import '../../scss/error.scss';

let pearsFacts = [
  'Pears used to be called “butter fruit” for their soft, butter-like texture.',
  'Pear tree wood is often used to construct musical instruments, furniture, and wooden décor.',
  'The majority of pears sold in the United States are grown on the west coast, primarily Oregon and Washington.',
  'There are over 3000 varieties of pears worldwide.',
  'Pears are fat-free and cholesterol-free.',
  'Pears are an excellent source of fiber and vitamin C for only 100 calories per serving!',
  'The United States is one of the largest producers of pears in the world.',
  'A medium pear has about 100 calories.',
  'Although pears mature on the tree, they ripen best off the tree.',
  'Every United States pear is picked by hand.',
  'Pears are a part of the rose family.',
  'Pears are native to Asia and Europe. The first North American pear tree was planted by the Massachusetts Bay colony in 1620.',
  'Pears were used as a natural remedy for nausea in ancient Greece.',
  'The Bartlett is the most popular variety of pear in the United States.',
];
window.$('#fact').html(pearsFacts[Math.floor(Math.random() * pearsFacts.length)]);
